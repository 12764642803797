.wrapper {
  animation: fadeIn 1.5s;
}

.waves {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.descTicket button {
  color: #da784b;
  margin-left: 5px;
}

.descTicket span {
  line-height: normal;
}

.wave {
  padding: var(--ticket-padding);
  width: var(--ticket-width);
  background-color: var(--ticket-bg);
  border-radius: var(--ticket-border-radius);
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
}

.ribbon {
  width: 25%;
  position: absolute;
  left: 40%;
  top: -22%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.top {
  /* background-color: var(--ticket-bg); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

.top span {
  font-size: var(--ticket-title);
  /* background-color: var(--ticket-bg); */
  color: black;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  /* max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; */
}

.logo {
  background-image: var(--btn-gradient);
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 1.5rem;
  fill: white;
  cursor: pointer;
}

.logo-disabled {
  background-image: var(--btn-gradient-disabled);
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 1.5rem;
  cursor: not-allowed;
  fill: white;
}

.price {
  color: black;
  font-weight: 700;
  font-size: 1rem;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  /* max-height: 100%; */
}

.description li {
  line-height: 20px;
  color: black;
  font-size: 12px;
  margin-left: 1rem;
  margin-top: 8px;
}

.description p {
  line-height: 20px;
  color: black;
  font-size: 12px;
}

.description {
  line-height: 20px;
  color: black;
  font-size: 12px;
}

.counter {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.counter span {
  font-size: 1.6rem;
  font-weight: 700;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.details span {
  color: gray;
  font-weight: 400;
}

.details .arrow {
  fill: var(--btn-gradient);
}

.wave_status {
  position: absolute;
  color: red;
  bottom: 1rem;
  right: 1rem;
  height: 1.5rem;
  line-height: 10px;
  background-image: var(--btn-gradient);
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: white;
  justify-content: center;
  padding: 1rem !important;
  border-radius: 5px;
}

.wave-unavailable {
  background-color: #767676;
  /* replace with the actual color you want */
  padding: var(--ticket-padding);
  width: var(--ticket-width);
  border-radius: var(--ticket-border-radius);
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: not-allowed;
}

.access_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  line-height: 22px;
  position: relative;
}

.access_code_input {
  background-color: white !important;
  color: black !important;
  line-height: 22px;
  top: -1rem;
}

.access_code_input {
  height: 20px !important;
  width: 10rem !important;
  font-size: 18px;
  padding: 1rem !important;
}

.button {
  color: black;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}