.all {
  overflow-y: auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(38px) brightness(60%);
  z-index: -1;
}

.wrapper {
  /* margin-top: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container {
  height: calc(100vh - 100px);

  width: var(--main-width-event-details);
  position: relative;
}

.border {
  border-bottom: 0.2px solid #7b57636c;
}

.visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.appReturn {
  display: none;
}

.invitesDialog {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: var(--ticket-width);
}

.invitesDialog2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: var(--ticket-width);
}

.invitesDialog .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin-bottom: 10px;
  align-self: center;
}

.invitesDialog .info p:nth-child(1) {
  color: white;
  font-weight: 800;
  font-size: clamp(1.3rem, 2.5vw, 1.1rem);
  ;
}

.invitesDialog .info p:nth-child(2) {
  color: rgba(255, 255, 255, 0.507);
  font-weight: 800;
  font-size: clamp(0.1rem, 2.1vw, 0.8rem);
  line-height: normal;
  align-self: center;
}

.invitesDialog .info p:nth-child(3) {
  color: rgba(255, 255, 255, 0.507);
  font-weight: 800;
  font-size: clamp(0.1rem, 2.1vw, 0.8rem);
  line-height: normal;
  align-self: center;
}

.invitesDialog button {

  background-color: white;
  font-weight: 900;
  color: black;
  font-size: clamp(1.2rem, 2.5vw, 1.1rem);
  padding: 2px 25px;
  border-radius: 15px;
  cursor: pointer;

}

.invitesDialog2 button {

  background-color: white;
  font-weight: 900;
  color: black;
  font-size: clamp(1.2rem, 2.5vw, 1.1rem);
  padding: 2px 25px;
  border-radius: 15px;
  cursor: pointer;

}

.invitesDialog div:nth-child(2) {
  display: flex;
  justify-content: space-between;

}

.invitesDialog2 div:nth-child(2) {
  align-self: flex-end;
}

.invitesDialog1 input {
  width: 100%;
  border-radius: 15px;
  padding-left: 5px;
  margin: 0 auto;
  border: none;
  color: black;
  background-color: white;
}

@media screen and (max-width:650px) {
  .appReturn {
    display: flex;
    justify-content: space-between;
    padding: 10px 2.5%;
    background-color: #1b212d;
    color: white;
  }

  .appReturn a {
    background-image: var(--btn-gradient);
    color: white;
    line-height: normal;
    border-radius: 15px;
    font-size: 12px;
    padding: 5px 10px;
    height: fit-content;
    margin: auto 0;
  }

  .invitesDialog .info p:nth-child(1) {
    font-size: 1.6rem;
  }

  .invitesDialog .info p:nth-child(2) {
    font-size: 1rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .invitesDialog .info p:nth-child(3) {
    font-size: 1rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

}
.description_wrap {
  padding: 1rem 0;
}

.description_wrap img {
width: 100%;
border-radius: 15px;
margin-top: 5px;
}

.description_wrap p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
  padding: 0.5rem 0;
}

@media screen and (max-width:600px) {
  .description_wrap {
    padding: 1rem 25px;
  }
}