.wrapper {
  padding: 1rem 0;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tickets {
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ticket {
  width: var(--ticket-width);
  height: 90px;
  background-color: var(--ticket-bg);
  border-radius: var(--ticket-border-radius);
  cursor: pointer;
  padding: var(--ticket-padding);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket span {
  font-size: var(--ticket-title);
  background-color: var(--ticket-bg);
  color: black;
  font-weight: 700;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.details span {
  color: gray;
  font-weight: 400;
  font-size: 1rem;
}

.details .arrow {
  fill: var(--btn-gradient);
}

.tickets_group_big {
  border-radius: 20px;
  margin-top: 1rem;
}

.groupContainer {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
}

.groupItem {
  /* overflow: hidden; */
  line-height: 22px;

  color: black;
  width: var(--ticket-width);
}

.column_group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tickets_group {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.row_group {
  display: flex;
  justify-content: space-between;
  height: 90px;
  width: var(--ticket-width);
  background-color: var(--ticket-bg);
  border-radius: var(--ticket-border-radius);
  cursor: pointer;
  padding: var(--ticket-padding);
  width: 100%;
  align-items: center;
}

.row_group span {
  font-size: var(--ticket-title);
  background-color: var(--ticket-bg);
  color: black;
  line-height: 22px;
  font-weight: 700;
  min-width: 80%;
  max-width: 80%;
  -webkit-line-clamp: 3;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.icon_group {
  font-size: 2rem;
  transition: transform 0.3s ease;
  fill: #b0344a;
}

.icon_group.rotate {
  transform: rotate(180deg);
}